
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  onMounted
} from 'vue'
import { ElForm, ElMessage } from 'element-plus'
import moment from 'moment-timezone'
import {
  getUsers, update
} from '@/apis/user'
import { UserInfoModel } from '@/model/userModel'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup() {
    const { t } = useI18n()
    const dataForm = ref(ElForm)
    const dataMap = reactive({
      tableKey: 0,
      list: Array<UserInfoModel>(),
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        pageSize: 10,
        keywords: ''
      },
      pageviewsData: [],
      downloadLoading: false,
      formatTime(d: any) {
        moment.tz.setDefault('Asia/Shanghai')
        return d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : ''
      },
      handleCurrentChange(page?: any) {
        dataMap.getList(page)
      },
      handleSizeChange(val: any) {
        dataMap.getList(null, val)
      },
      async handleUpdate(row: any) {
        await update(row.id, row).catch((e) => { console.log(e) })
        ElMessage({
          message: '操作成功',
          type: 'success'
        })
      },
      async getList(index?: any, size?: any) {
        if (index) {
          dataMap.listQuery.page = index
        }
        if (size) {
          dataMap.listQuery.pageSize = size
        }
        dataMap.listLoading = true
        const data = await getUsers(dataMap.listQuery)
        dataMap.list = data?.data.items ?? []
        dataMap.total = data?.data.total ?? 0
        setTimeout(() => {
          dataMap.listLoading = false
        }, 0.5 * 1000)
      },
      handleFilter() {
        dataMap.listQuery.page = 1
        dataMap.getList()
      }
    })
    onMounted(() => {
      console.log(typeof ElForm)
      dataMap.getList(null, 10)
    })
    return { t, ...toRefs(dataMap), dataForm }
  }
})
